import React from "react";
import cx from "classnames";
import home from "../images/undraw_taking_notes_tjaf.svg";
import homeRight from "../images/home-right.jpg";
import homeLower1 from "../images/home-lower-1.jpg";
import homeLower2 from "../images/home-lower-2.jpg";
import homeLower3 from "../images/home-lower-3.jpg";
import homeTop from "../images/home-top-3.png";
import branding from "../images/noun_branding_1885335.svg";
import processing from "../images/noun_The Process_1885341.svg";
import modeling from "../images/noun_3d modeling_1885342.svg";
import { Helmet } from "react-helmet";
import { Link, withPrefix, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-indigo-700 hover:bg-indigo-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2">
      <Link
        to={url}
        className="text-2xl text-indigo-700 hover:text-indogo-600 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
    </div>
  );
};

function Index({ data }) {
  const services = data.services.edges;

  return (
    <Layout headerClass="relative bg-white">
      <SEO title="Home" />
      <div
        className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"
        style={{
          backgroundImage: `url(${homeTop})`
        }}
      >
        <div className="flex flex-col mt-10">
          <div className="w-4/5 md:w-3/4 lg:w-7/12 mt-20 font-serif font-hairline self-start">
            <h1 className="text-3xl md:text-5xl text-indigo-700 leading-tight">
              A&F Pro Painting
            </h1>
            <p className="text-base">
              The best way to make a good first impression is with a fresh coat of paint. It’s like a book cover: the first thing anybody notices.
              Nothing is more beautiful than a perfect paint job, and nobody does it better than A&F Pro Painting. As a dedicated team of local painters,
              we’re committed to giving you the best treatment possible. We’ll make sure you always make a good first impression.
          </p>
          </div>
          <div className="container w-3/4 sm:w-7/12 lg:w-5/12 xl:1/4 mt-10 self-start">
            <div
              className="bg-white rounded flex flex-col sm:flex-row items-start sm:items-center text-sm p-4"
              style={{
                boxShadow:
                  "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
              }}
            >
              <div className="flex-1">
                <p>
                  <strong>Phone: </strong>
                (310) 986-5781
              </p>
                <p>
                  <strong>Email: </strong>
                fernando@afpainting.co
              </p>
              </div>
              <div className="flex-initial mt-6 sm:mt-0">
                <Link to="/contact">
                  <StyledButton>Contact</StyledButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <div class="grid grid-cols-3 gap-4 mb-20">
            <div class=" sm:col-span-3 md:col-span-2 col-span-3">
              <p className="mb-2 text-2xl text-gray-1000  self-center">
                Painting Services Done Right
              </p>
              <p className="text-base" style={{ textAlign: 'justify' }}>
                You may want to give your home or business a simple upgrade, or maybe you want a complete makeover.
                You could be a longtime homeowner doing renovations, or a business owner giving your workplace a fresh new look.
                Whatever your vision, we will achieve it. We’ll listen to you, provide options, and give you advice and feedback.
                You can be sure we’ll always give you honest opinions and fair estimates.
              </p>
              <br />
              <p className="text-base" style={{ textAlign: 'justify' }}>
                Plus, with our wide range of painting services, you’ll have a lot to choose from.
                Why search around for deals when we can provide everything you need at a fair price? Painting is our specialty,
                but we do so much more than that. Our services include stucco repair, wallpaper hanging and removal, and much more.
                You can browse our website to see all of our services. We have everything you need to improve the look of your home or business,
                and at our rates, you can afford it all.
              </p>
            </div>
            <div class="sm:col-span-3 md:col-span-1 col-span-3">
              <img alt='home-right' width="100%" style={{ height: "100%" }} className="rounded" src={withPrefix(homeRight)} />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex flex-wrap justify-center items-stretch">
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2 p-2">
              <img alt='home-right' width="100%" style={{ height: '100%', maxHeight: 245 }} className="rounded" src={withPrefix(homeLower1)} />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2 p-2">
              <img alt='home-right' width="100%" style={{ height: '100%', maxHeight: 245 }} className="rounded" src={withPrefix(homeLower2)} />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2 p-2">
              <img alt='home-right' width="100%" style={{ height: '100%', maxHeight: 245 }} className="rounded" src={withPrefix(homeLower3)} />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <div class="grid grid-cols-2 gap-4">
            <div class="sm:col-span-3 md:col-auto col-span-3">
              <p className="mb-2 text-2xl text-gray-1000  self-center">
                Not Your Average Painting Company
              </p>
              <p className="text-base" style={{ textAlign: 'justify' }}>
                Commercial and residential painting is more than an in-and-out job for us—it’s all about trust.
                We understand that your property is important to you. When you hire us, you can be sure we’ll treat your
                place, your family, neighbors, possessions, and animals with total respect. A&F Pro Painting will treat
                your home or business with care and attention because we value your trust. As leading local painting
                contractors, we’re committed to delivering top-quality results. For us, it’s not just a job– it’s our pride
                and joy. We take pride in our work and bring joy to everyone we work with. We put a part of ourselves in
                everything we do and treat our customer’s homes and businesses as if they were our own. That’s why we have
                so many happy customers.
              </p>
            </div>
            <div class="sm:col-span-3 md:col-auto col-span-3">
              <p className="mb-2 text-2xl text-gray-1000  self-center">
                The Best Professional Painters
              </p>
              <p className="text-base" style={{ textAlign: 'justify' }}>
                We’re more than a painting company––we’re professionals. Why go to the trouble of doing it yourself when we
                can do it for you? We make the process simple, speedy, and easy. Instead of calling your friends to help, call
                us. We’re both professional and friendly. Our staff is comprised of painters who will use their expertise and
                training to give you exactly what you want. We’re fully equipped with the best tools and supplies. We listen
                to you and work hard to achieve your vision. And after finishing, we’ll always leave your place spotless and
                clean. If you’re looking for the best painting service in your area, look no further. Come to A&F Pro Painting.
                Our fair prices and professional staff are the perfect blend.
              </p>
            </div>
          </div>
        </div>
      </div >
    </Layout >
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          excerpt
        }
      }
    }
  }
`;

export default Index;
